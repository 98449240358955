<template>
  <v-container fluid>
    <div class="md-card md-theme-default mt-8 shadow rounded-5">
      <div class="md-card-content md-card-table">
        <div>
          <div class="d-flex justify-space-between align-center">
            <SvgIcon
              class="text-2xl font-semibold"
              text="Customer Visitation Log"
            >
            </SvgIcon>
            <v-spacer />

            <div class="d-flex align-center gap-x-4 mt-2">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                filled
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    dense
                    class="q-text-field shadow-0"
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    flat
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                filled
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="q-text-field shadow-0"
                    dense
                    v-model="date2Formatted"
                    outlined
                    readonly
                    v-on="on"
                    flat
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                class="mr-2 bg-blue text-white"
                height="40"
                elevation="0"
                @click="getSchedules"
              >
                Go
              </v-btn>
            </div>
            <v-col md="4" class="text-right">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="checkExportPermission($modules.reports.discount.slug)"
                    class="export-button mt-2"
                    elevation="0"
                    height="40"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <SvgIcon text="Export Report">
                      <template v-slot:icon>
                        <ExportIcon />
                      </template>
                    </SvgIcon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="reportDownload('pdf')">
                    <v-list-item-icon>
                      <v-icon>mdi-file-pdf-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Export as PDF</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="reportDownload('excel')">
                    <v-list-item-icon>
                      <v-icon>mdi-file-excel-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Export as Excel</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </div>
          <br /><br />
          <div class="table-responsive">
            <table class="table border-collapse text-center">
              <thead>
                <tr class="opacity-70 tr-neon tr-rounded text-center">
                  <!-- <th class="">Check Out</th> -->
                  <th>
                    <div class="ml-10">Check In Time</div>
                  </th>
                  <th>
                    <div class="ml-10">Check Out Time</div>
                  </th>
                  <th>
                    <div class="ml-10">Customer Name</div>
                    <div class="search_column">
                      <v-autocomplete
                        solo
                        filled
                        label="Customer"
                        v-model="searchParams.name"
                        item-value="name"
                        item-text="name"
                        :items="names"
                        :loading="isLoading"
                        :search-input.sync="name"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                      ></v-autocomplete>
                    </div>
                  </th>
                  <th>
                    <div class="ml-10">Mobile</div>
                    <div class="search_column">
                      <v-autocomplete
                        v-model="searchParams.mobile"
                        :items="phones"
                        :loading="isLoading"
                        :search-input.sync="phone"
                        class="q-autocomplete shadow-0"
                        filled
                        flat
                        hide-details
                        item-text="mobile"
                        item-value="mobile"
                        label="Mobile"
                        solo
                        @change="getFilterData"
                      ></v-autocomplete>
                    </div>
                  </th>
                  <th>
                    <div class="ml-10">Email</div>
                    <div class="search_column">
                      <v-autocomplete
                        solo
                        filled
                        label="email"
                        v-model="searchParams.email"
                        item-value="email"
                        item-text="email"
                        :items="emails"
                        :loading="isLoading"
                        :search-input.sync="email"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                      ></v-autocomplete>
                    </div>
                  </th>
                  <th>
                    <div class="ml-10">Nationality</div>
                  </th>

                  <!-- <th>
                    <div>
                      <div class="ml-10">Scanned User</div>
                      <div class="search_column">
                        <v-autocomplete
                          solo
                          filled
                          label="Scanned User"
                          v-model="searchParams.cashier_name"
                          item-value="name"
                          item-text="name"
                          :items="cashier_names"
                          :loading="isLoading"
                          :search-input.sync="cashier_name"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          hide-details
                        ></v-autocomplete>
                      </div>
                    </div>
                  </th> -->

                  <th class="">
                    <div class="ml-10">Age</div>
                    <div class="search_column">
                      <v-select
                        v-model="searchParams.age"
                        :items="AgeRange"
                        @change="ageChange"
                        label="Age"
                        ref="ageRef"
                        solo
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                      >
                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <div style="width: 150px">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-text-field
                                    label="Start Age"
                                    class="shrink"
                                    v-model="fromAge"
                                    append-icon="mdi-keyboard_arrow_down"
                                  ></v-text-field>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-text-field
                                    label="End Age"
                                    class="shrink"
                                    v-model="toAge"
                                    append-icon="mdi-keyboard_arrow_down"
                                  ></v-text-field>
                                </v-list-item-subtitle>
                                <v-btn small color="primary" @click="ageOkClick"
                                  >Ok</v-btn
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="customer in customersList" :key="customer.id">
                  <td>
                    <span v-if="customer.check_in">{{
                      customer.check_in | timeStamp
                    }}</span>
                    <span v-else>NA</span>
                  </td>
                  <td>
                    <span v-if="customer.check_out">{{
                      customer.check_out | timeStamp
                    }}</span>
                    <span v-else>NA</span>
                  </td>
                  <td>
                    <div style="width: 200px">
                      <v-btn
                        small
                        color="#00b0af"
                        class="d-inline-block text-truncate"
                        style="max-width: 200px; text-transform: none"
                        dark
                        @click="showUserModel(customer.id)"
                      >
                        <span class="text-truncate"
                          >{{ customer.first_name }}
                          {{ customer.last_name }}</span
                        ></v-btn
                      >
                    </div>
                  </td>
                  <td>
                    <div style="width: 200px">
                      {{ customer.mobile }}
                    </div>
                  </td>
                  <td>
                    <div style="width: 200px">
                      {{ customer.email }}
                    </div>
                  </td>
                  <td>
                    <div style="width: 200px">
                      {{ customer.nationality ? customer.nationality : "NA" }}
                    </div>
                  </td>

                  <!-- <td>
                    <div>
                      {{ customer.scanner_user_name }}
                    </div>
                  </td> -->

                  <td>
                    <div>
                      {{ customer.age ? customer.age : "NA" }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination
          v-model="page"
          :length="totalPages"
          class="new-pagination"
          total-visible="7"
        ></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
          <span class=""> Result </span>
          <div style="width: 80px">
            <v-select
              v-model="perPage"
              :items="[10, 15, 25, 50]"
              :menu-props="{ bottom: true, offsetY: true }"
              class="q-autocomplete text-sm"
              flat
              hide-details
              solo
              @change="getSchedules"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ExportIcon from "@/assets/images/misc/export-icon.svg";

import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {
    ExportIcon,
    SvgIcon,
  },
  data() {
    return {
      customersList: [],
      btnShow: false,
      AgeRange: ["All", "18-25", "26-30", "31-35"],
      Age: [],
      toAge: null,
      fromAge: null,
      names: [],
      name: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      fileName: null,
      isLoading: false,
      page: 1,
      perPage: null,
      totalPages: 1,
      cashier_names: [],
      cashier_name: null,
      cashierPermission: false,
      menu1: null,
      menu2: null,
      userModel: { userID: null, type: "details" },
      searchParams: {
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      display_numbers: [],
      display_number: null,
    };
  },

  created() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
  mounted() {
    this.getSchedules();
  },

  computed: {
    ...mapGetters({
      checkReadPermission: "checkReadPermission",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
  },
  watch: {
    page() {
      this.getSchedules();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    cashier_name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("cashier_name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
  },
  methods: {
    gotoPage(route) {
      this.$router.push(route);
    },
    ageOkClick() {
      if (this.fromAge != null && this.toAge != null) {
        this.searchParams.from_age = this.fromAge;
        this.searchParams.to_age = this.toAge;
        let customAgeRange = this.fromAge + "-" + this.toAge;
        this.AgeRange.push(customAgeRange);
        this.searchParams.age = customAgeRange;
        this.toAge = null;
        this.fromAge = null;
        this.$refs.ageRef.blur();
        this.getSchedules();
      }
    },
    ageChange() {
      if (this.searchParams.age != "All") {
        let data = this.searchParams.age.split("-");
        this.searchParams.from_age = data[0];
        this.searchParams.to_age = data[1];
        this.getSchedules();
      } else {
        this.allAge();
      }
    },
    allAge() {
      delete this.searchParams.from_age;
      delete this.searchParams.to_age;
      delete this.searchParams.age;
      this.getSchedules();
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    getFilter() {
      let url =
        "&from_date=" +
        this.searchParams.from_date +
        "&to_date=" +
        this.searchParams.to_date;
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (
        typeof this.searchParams.name != "undefined" &&
        this.searchParams.name != null
      ) {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.cashier_name == "All") {
        this.searchParams.cashier_name = null;
      } else if (
        typeof this.searchParams.cashier_name != "undefined" &&
        this.searchParams.cashier_name != null
      ) {
        url += "&cashier_name=" + this.searchParams.cashier_name;
      }
      if (
        typeof this.searchParams.from_age != "undefined" &&
        typeof this.searchParams.to_age != "undefined"
      ) {
        url +=
          "&from_age=" +
          this.searchParams.from_age +
          "&to_age=" +
          this.searchParams.to_age;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (typeof this.searchParams.email != "undefined") {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (typeof this.searchParams.mobile != "undefined") {
        url += "&mobile=" + this.searchParams.mobile;
      }
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      if (this.fileName != null)
        this.fileName = "C-Report-" + this.fileName.replace(/\s/g, "");
      else this.fileName = "Attendance-Report";
      return url;
    },
    getSchedules() {
      let url = "";
      url = this.getFilter();
      this.showLoader("Loading");
      this.$http
        .get(
          "venues/reports/customer-visitation-report?page=" +
            this.page +
            "&per_page=" +
            (this.perPage != null ? this.perPage : 10) +
            url
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.customersList = response.data.data;
            this.totalPages = response.data.total_pages;
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
        .get("venues/customers/filters?field=" + type + "&search=" + val)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            }
            if (type == "cashier_name") {
              this.cashier_names = response.data.data;
              this.cashier_names.unshift(val);
              this.cashier_names.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getFilterData() {
      this.page = 1;
      this.getSchedules();
    },

    customerForward(id) {
      this.$router.push(
        {
          name: "CustomersByID",
          params: { id: id },
        },
        () => {}
      );
    },

    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "customer-visitation-report-pdf";
      } else if (type == "excel") {
        link = "customer-visitation-report-excel";
      }
      let url = "";
      url = this.getFilter();
      if (!url) return;
      this.showLoader("Downloading report");
      console.log(link);
      this.$http
        .get(`venues/reports/` + link + `/download?${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.light-blue-text {
  color: rgba(17, 42, 70, 0.8) !important;
}
</style>
